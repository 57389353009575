import React, { useEffect, useState, useRef, useMemo } from "react"
import "./index.less"
import { groupBy, prop, intersection } from "ramda"
import data from "./data.json"
const loadText = require("src/utils").loadText
const urlData = loadText("url")
import { handleMapData } from "../../utils"
import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import { lang } from "../../utils"
const L = typeof window !== "undefined" ? require("leaflet") : () => null
if (typeof window !== "undefined") {
  require("leaflet.markercluster")
}
const productNameTrans = (name: string) => {
  if (name === "全球智能加速器") {
    return ""
  }
  if (name === "Global Intelligent Accelerator") {
    return "上海层峰 Global Accelerator"
  }
  return name
}
const clusterObj = handleMapData(data.data.list).reduce((acc, cur) => {
  const countryName = lang === "en" ? cur.countryNameEn : cur.countryName
  // const countryName =
  // _countryName === "Taiwan" && lang === "zh" ? "China" : _countryName
  const fd = {
    id: cur.code,
    latitude: cur.lat,
    longitude: cur.lng,
    products: cur.products.map(productNameTrans),
    name: `${lang === "en" ? cur.cityNameEn : cur.cityName}-${cur.code}`,
    country: countryName || "Other",
  }
  let d = acc[fd.country]
  if (d) {
    d.push(fd)
  } else {
    acc[fd.country] = [fd]
  }
  return acc
}, {})

const cluster = Object.keys(clusterObj).map(key => clusterObj[key])
const products = (lang === "en"
  ? [urlData.bmc, urlData.sdn, urlData.edcs, urlData.gia, urlData.ipt]
  : [urlData.bmc, urlData.sdn, urlData.edcs, urlData.ipt]
).map(d => d.name)
type Node<T> = T & {
  id: string
  longitude: string
  latitude: string
}
type Center = {
  latlng: [number, number]
  zoom: number
}
type Props<T> = {
  cluster: Array<Node<T>[]> // 地图数据
  render: (nodes: Node<T>[]) => string // 自定义maker
  tooltip: (nodes: Node<T>[], cursorPos?: { x: number; y: number }) => string // 自定义tooltip
  center?: Center // 地图中心和缩放等级
  highlight?: number[]
  showHighlight?: boolean
  style?: React.CSSProperties // 自定义样式
}
function Map<T>({
  cluster,
  render,
  tooltip,
  center,
  style = {},
  showHighlight = false,
  highlight,
}: Props<T>) {
  const map = useRef<any>(null)
  const markerGroup = useRef<any>(null)
  const highlightRef = useRef<any>(null)
  useEffect(() => {
    const southWest = L.latLng(-89.98155760646617, -180)
    const northEast = L.latLng(89.99346179538875, 180)
    const maxBounds = L.latLngBounds(southWest, northEast)

    map.current = L.map("map", {
      worldCopyJump: true,
      maxBounds,
      scrollWheelZoom: false,
      maxBoundsViscosity: 1.0,
    }).setView([20, 0], 2)
    if (lang === "en") {
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          minZoom: 2,
          id: "zenlayer/ckexnlvse092b19qn1fx99a1c",
          accessToken:
            "pk.eyJ1IjoiemVubGF5ZXIiLCJhIjoiY2tleG0yNWRqMDFlbDJ0b284Mmp1a2liZiJ9.vCwxLZqxsrN3gmTi28sl2Q",
        }
      ).addTo(map.current)
    } else {
      const tileConfig = {
        maxZoom: 18,
        minZoom: 2,
        subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
        accessToken: "65ab90e305d48f4c05232eaabca4945a",
      }
      L.tileLayer(
        "https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk={accessToken}",
        tileConfig
      ).addTo(map.current)
      L.tileLayer(
        `https://t{s}.tianditu.gov.cn/DataServer?T=${
          lang === "zh" ? "c" : "e"
        }va_w&X={x}&Y={y}&L={z}&tk={accessToken}`,
        tileConfig
      ).addTo(map.current)
    }
  }, [])
  useEffect(() => {
    if (highlight.length === 2 && showHighlight) {
      if (highlightRef.current) {
        highlightRef.current.remove()
      }
      highlightRef.current = L.marker(highlight, {
        icon: L.divIcon({
          className: "",
          html: '<div class="map-highlight-icon"></div>',
        }),
      }).addTo(map.current)
    }
  }, [highlight])
  useEffect(() => {
    if (!map.current) {
      return
    }
    if (markerGroup.current) {
      markerGroup.current.forEach(markers => {
        map.current.removeLayer(markers)
      })
    }
    const nodeMap = (function () {
      let res: any = {}
      cluster.forEach(nodes => {
        nodes.forEach(node => {
          res[node.id] = node
        })
      })
      return res
    })()

    cluster.forEach(nodes => {
      const markers = L.markerClusterGroup({
        maxClusterRadius: 20,
        showCoverageOnHover: false,
        iconCreateFunction: function (c) {
          return L.divIcon({
            className: "",
            html: render(
              c
                .getAllChildMarkers()
                .map(({ options }: any) => nodeMap[options.alt])
            ),
          })
        },
      })
      markers
        .on("clustermouseover", function (c) {
          const ns = c.layer
            .getAllChildMarkers()
            .map(({ options }: any) => nodeMap[options.alt])

          const content = tooltip(ns, c.containerPoint)

          L.popup({ className: "leaflet-custom-tooltip" })
            .setLatLng(c.layer.getLatLng())
            .setContent(content)
            .openOn(map.current)
        })
        .on("clusterclick", function (c) {
          map.current.closePopup()
        })
      nodes.forEach(node => {
        markers.addLayer(
          L.marker([node.latitude, node.longitude] as any, {
            alt: node.id,
            icon: L.divIcon({
              className: "",
              html: render([node]),
            }),
          })
            .bindPopup(tooltip([node]), {
              className: "leaflet-custom-tooltip",
            })
            .on("mouseover", function (ev) {
              ev.target.openPopup()
            })
        )
      })
      if (markerGroup.current) {
        markerGroup.current.push(markers)
      } else {
        markerGroup.current = [markers]
      }
      map.current.addLayer(markers)
    })
  }, [cluster])
  useEffect(() => {
    if (center && map.current) {
      map.current.closePopup()
      map.current.flyTo(center.latlng, center.zoom)
    }
  }, [center])
  return <div id="map" style={style}></div>
}

const ResourceMap = ({
  defaultSelected,
  showProductLabel = true,
  showData = true,
  highlight = [],
  initPos,
  showHighlight = false,
}: {
  defaultSelected?: string[]
  showProductLabel?: boolean
  showData?: boolean
  initPos?: any
  highlight?: number[]
  showHighlight?: boolean
}) => {
  const height = 582
  const mapEl = useRef<HTMLDivElement>(null)
  const [center, setCenter] = useState<Center>()
  const [activeP, setActiveP] = useState<string[]>([])

  const handleFilter = (v: string) => {
    setActiveP(
      // activeP.includes(v) ? activeP.filter(p => p !== v) : [...activeP, v]
      [v]
    )
  }

  const data = useMemo(() => {
    return cluster.reduce((acc, cur) => {
      const _cur = cur.reduce((a, c) => {
        const p = intersection(c.products, activeP)
        if (p.length) {
          a.push(c)
        }
        return a
      }, [])
      if (_cur.length) {
        acc.push(_cur)
      }
      return acc
    }, [])
  }, [activeP])
  useEffect(() => {
    if (highlight.length === 2) {
      setCenter({
        latlng: highlight,
        zoom: 10,
      })
    }
  }, [highlight])
  useEffect(() => {
    if (initPos) {
      setCenter(initPos)
    }
  }, [])
  useEffect(() => {
    if (defaultSelected) {
      setActiveP(defaultSelected)
    } else {
      setActiveP(products)
    }
    if (mapEl.current) {
      const handleClick = (e: MouseEvent) => {
        const { dataset = {} } = (e.target || {}) as any
        if (dataset.lat && dataset.lng) {
          setCenter({
            latlng: [dataset.lat, dataset.lng],
            zoom: 10,
          })
        }
      }
      mapEl.current.addEventListener("click", handleClick, true)
      return () => {
        if (mapEl.current) {
          mapEl.current.removeEventListener("click", handleClick)
        }
      }
    }
    return () => {}
  }, [mapEl])

  return (
    <div className="resource-map" ref={mapEl}>
      {showProductLabel && (
        <ul className="resource-map--filter">
          <li
            className={
              activeP.length === products.length ? "active" : undefined
            }
            onClick={() => {
              setActiveP(products)
            }}
          >
            {lang === "en" ? "All" : "所有产品"}
          </li>
          {products.map(p => (
            <li
              key={p}
              className={activeP.includes(p) ? "active" : undefined}
              onClick={() => {
                handleFilter(p)
              }}
            >
              {p}
            </li>
          ))}
        </ul>
      )}
      <Map<any>
        cluster={showData ? data : []}
        highlight={highlight}
        showHighlight={showHighlight}
        render={nodes => {
          const hasResource = true
          const type = hasResource ? "true" : "false"
          if (nodes.length === 1) {
            return `<div class="map-marker map-marker__hollow__${type}"></div>`
          } else {
            return `<div class="map-marker map-marker__solid__${type}">
                  ${
                    nodes.length > 1
                      ? `<span class="map-marker--icon">${nodes.length}</span>`
                      : null
                  }
                </div>`
          }
        }}
        tooltip={nodes => {
          // console.log(nodes)
          let group
          if (nodes.length > 1) {
            group = groupBy(prop("country"), nodes)
          }
          return nodes.length === 1
            ? `<div class="map-tooltip font-14" >
                ${nodes
                  .map(
                    ({ name, products }) =>
                      `<div class="t-color semi-bold">${name}</div>
                   <div class="map-tooltip-product-container">
                    ${products
                      .map(
                        nameEn =>
                          `<div class="map-tooltip-item">${nameEn}</div>`
                      )
                      .join("")}
                   </div>`
                  )
                  .join("")}
              </div>`
            : `<div>
                ${Object.keys(group).map(
                  key =>
                    `<div class="map-tooltip">
                    <div class="map-tooltip-country semi-bold">${key}</div>
                    <div>
                      ${group[key]
                        .map(
                          ({ name, latitude, longitude }) =>
                            `<a class="map-tooltip-item font-14" data-lat="${latitude}" data-lng="${longitude}">${name}</a>`
                        )
                        .join("")}
                    </div>
                  </div>`
                )}
              </div>`
        }}
        center={center}
        style={{
          height: `${height}px`,
        }}
      />
    </div>
  )
}

export default ResourceMap
export const CityGroup = ({ data, onClick }) => {
  const [active, setActive] = useState("")
  return (
    <div className="map-cities block-middle" style={{ maxWidth: "1000px" }}>
      {data.map(({ name, latitude, longitude }) => (
        <div
          className={`map-cities-item ${active === name ? "active" : ""}`}
          onClick={() => {
            setActive(name)
            onClick([latitude, longitude])
          }}
        >
          {name}
        </div>
      ))}
    </div>
  )
}
